.login-container {
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 0 120px;
    background: #fff;
    border-radius: 25px;
}
.logo {
    padding-top: 30px;
    padding-bottom: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.form-container * {
    width: 100%;
}
.login-container .message {
    margin-bottom: -20px;
}
.checkbox-container {
    margin-top: 22px;
}
.checkbox-container input {
    margin: 0 12px;
    height: 15px;
    width: 15px;
}
.checkbox-container input:checked {
    background: #008941;
}
.checkbox-container label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
}
