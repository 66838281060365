.page.ad {
    //height: fit-content;
    //min-height: 100vh;
}
.remove-bar-icon svg, .img-banner-container svg path {
    fill: #D01829;
}
.add-bars-to-ad-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    top: -60px;
    text-decoration: underline;
}
.add-bars-to-ad-container a {
    margin: 10px 12px;
}
.add-bars-to-ad-container a.remove-bars:hover {
    color: #D01829;
    font-weight: bold;
}
.add-bars-to-ad-container a.add-bars:hover {
    color: #000;
    font-weight: bold;
}
.page.ad .container-column-2 {
    padding-bottom: 0px !important;
}
.ad-bars-list {
    margin-top: -36px;
    margin-bottom: 20px;
}
.container-icon-selected {
    margin-top: -25px;
}

/* live ad banner display */

/*
.ad-banner-container {

}
.ad-banner {

}

 */
.collapsible-ad-banner {
    margin: auto;
    padding: 0px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: fit-content;
    width: 100vw !important;
}


.collapsible-ad-banner div {
    height: fit-content;
}



/*

<div class="wrapper">
    <img id="slide" src="https://cdn.xl.thumbs.canstockphoto.com/-drawings_csp14518596.jpg" />
</div>

*/