.sidebar {
    width: 390px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    z-index: 2;
    position: fixed;
}

.sidebar a {
    font-size: 15px;
    font-weight: 600;
    color: #707070;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.sidebar .logo {
    padding-top: 50px;
    padding-bottom: 20px;
    cursor: pointer;
}

.sidebar ul {
    margin: 0;;
    padding: 0;
    outline: none;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 68%;
    zoom: 0.86;
}

.sidebar ul li {
    margin: 14px 0;
    cursor: pointer;
}

.sidebar ul li.collapsed {
    margin: 14px 0;
}

.sidebar ul li .icon {
    margin: 0 15px;
}

.sidebar ul li .icon .icon-edit-primary {
    stroke: #707070;
}

.btn-logout {
    text-decoration: underline;
    color: #707070;
    align-self: center;
    position: absolute;
    bottom: 35px;
}

.btn-logout p {
    font-size: 8pt;
    font-weight: 200;
    cursor: pointer;
}

.sidebar ul li .submenu-item {
    font-size: 14px;
    cursor: pointer;
    opacity: 1;
    padding-bottom: 8px;
    padding-left: 55px;
}
.sidebar ul li .submenu-item.before {
    color: #707070;
}
.sidebar ul li .submenu-item.current {
    color: #707070;
}
.sidebar ul li .submenu-item.after {
    opacity: 0.39;
}

.sidebar.hide {
    display: none;
}
