.page.edit-menu-add-products {
    min-height: 100vh;
    height: 100% !important;
    justify-content: stretch;
}
.page.edit-menu-add-products .body {
    padding-right: 50px !important;
    margin-top: 120px;
    padding-top: 25px;
}
.masonry-create-menu-categories-pool {
    margin-top: 14px;
    padding-bottom: 220px;
}
.create-menu-category-pool-item, .create-menu-category-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-radius: 20px;
    background-color: #fff;
}
.create-menu-category-pool-item:active, .create-menu-category-pool-item.selected, .create-menu-category-item:active {
    border: 3px solid #008941;
}
.container-icon-selected {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: self-start;
    height: 100%;
    margin-top: -20px;
    margin-right: 20px;
}
.create-menu-category-item .container-icon-selected {
    align-items: center;
    margin-top: 0;
    width: fit-content;
}
.create-menu-category-item .container-icon-selected svg {
    visibility: visible;
    fill: #707070;
    width: 31px;
    height: 31px;
}

.create-menu-category-pool-item svg {
    visibility: hidden;
}
.create-menu-category-pool-item.selected svg {
    visibility: visible;
    fill: #008941;
    width: 31px;
    height: 31px;
}
.create-menu-category-pool-item .p-title-product {
    min-width: fit-content;
}

.create-menu-category-pool-item:hover, .create-menu-category-item:hover {
    cursor: grab;
}

.create-menu-category-pool-item .p-title-product, .create-menu-category-item .p-title-product {
    padding-left: 35px;
}
.create-menu-category-item {
    margin-bottom: 8px;
    box-shadow: 0px 3px 6px #00000029;
}

.button-add-category {
    opacity: 0.5;
}
.button-add-category:hover {
    opacity: 1;
    background: #008941;
}
.button-add-category:hover .button p {
    color: #fff;
}

.selected-category-drop-zone {

}
.drop-zone-category-pool {}


/* locked category item */
.create-menu-category-item.category.locked {
    background: #e9e9e9;
}
/*
.create-menu-category-item.category.locked:hover {
    cursor: default;
}
.create-menu-category-item.category.locked:active {
    border: none;
}

 */
