.sharepoint.main .page {
    height: auto;
}
.background-blurred {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-size: cover;
}

/* WELCOME BANNER */

.welcome-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    padding: 0 50px;
}
.welcome-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px;
    padding-right: 45%;
    border-radius: 9px;
    background-color: white;
}
.p-welcome-banner {
    font-size: 17px;
    color: #008941;
    font-weight: bold;
}
.p-welcome-banner.desc {
    font-size: 13px;
    color: #707070;
    font-weight: normal;
}
.sharepoint.img-welcome {
    height: 70px;
    object-fit: contain;
    padding: 0 8%;
}

/* COLLAPSABLE FOLDER DESCRIPTION BANNER*/
.collapsable-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    right: -70px;
    width: 90px;
    position: relative;
    overflow: hidden;
    border-radius: 9px;
    transition-duration: 0.7s;
    transition-property: right, min-width;
}
.collapsable-banner-container:hover {
    right: -50px;
    width: 100%;
}

.collapsable-banner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-radius: 9px;
    background-color: white;
    padding: 0 20px;
}
.collapsable-banner .p-welcome-banner.desc {
    margin: 0 40px;
}

.sharepoint.img-welcome.small {
    height: 40px;
    object-fit: contain;
}

/* SIDEBAR */
.sharepoint.sidebar .logo {
    pointer-events: none;
    cursor: pointer;
}
.sharepoint.sidebar a {
    font-size: 14px;
    /*color: #0A0A0A;*/
}
.sharepoint.sidebar a svg g path {
    /*fill: #0A0A0A;*/
}
.sharepoint.sidebar .tab.selected a {
    color: #008941;
}
.sharepoint.sidebar .tab.selected svg path {
    fill: #008941;
}
.sidebar-name {
    color: #008941;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 40px;

}

/* TOOLBAR */
.sharepoint.toolbar {
    width: 100%;
    height: 50px;
    display: flex;
    margin: 12px 0px;
    margin-left: 9px;
}
.sharepoint.toolbar .select-default__indicator-separator {
    display: none;
}
.sharepoint.toolbar .select-default__indicators path {
    color: #008941;
}

.mime-filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.mime-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 50px;
    background-color: white;
    border-radius: 9px;
    margin: 0 4px;
    font-size: 13px;
    color: #707070;
    cursor: pointer;
}
.mime-filter.selected {
    background-color: #008941;
    color: #ffffff;
}

.mime-filter:first-child {
    margin-left: 0px;
}

.sharepoint .select-default__control {
    height: 50px;
    border-radius: 9px;
}
.sharepoint .select-default__single-value {
    top: 58%;
}
.sharepoint.search-container input {
    height: 50px;
}
.sharepoint-file {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.sharepoint-button:hover, .sharepoint-button.selected {
    background-color: #008941 !important;
}
.sharepoint-button:hover > svg, .sharepoint-button.selected > svg {
    fill: #ffffff;
}
.sharepoint-button:hover > p, .sharepoint-button.selected > p {
    color: #ffffff;
}

.sharepoint-button.disabled {
    opacity: 0.5;
}
.sharepoint-button.disabled > svg, .sharepoint-button.disabled > p {
    fill: #707070;
    color: #707070;
}
.sharepoint-button.disabled:hover {
    background-color: #ffff !important;
    cursor: default;
}
.sharepoint-button.disabled:hover > svg {
    fill: #707070;
    color: #707070;
    cursor: default;
}
.sharepoint-button.disabled:hover > p {
    color: #707070;
}

.sharepoint.toolbar-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 9px;
/* box-shadow: 0 3 pt 6 pt #00000029; */
    background-color: #ffffff;
    cursor: pointer;
}

.sharepoint.toolbar-button.button-delete:hover {
    background-color: #b81921 !important;
}

.sharepoint.container-folders {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    padding: 21px 42px;
}
.sharepoint-folder {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    min-width: 200px;
    max-width: 200px;
    height: 200px;
    border-radius: 15px;
    box-shadow: 0 2pt 4pt #00000029;
    background-color: #ffffff;
}
.sharepoint-folder p {
    text-align: center;
}
.sharepoint-folder:hover {
    background-color: #008941;
    cursor: pointer;
}
.sharepoint-folder:hover p {
    color: #ffffff;
}
.sharepoint-folder.sort-folder {
    border: 4px #989898 solid;
}
.sharepoint.icon.sort-folder {
    position: absolute;
    fill: #008941;
    margin-left: 80px;
    margin-top: -80px;
}
.sharepoint-folder:hover .sharepoint.icon.sort-folder {
    fill: #fff;
}

.sharepoint-folder.disabled {
    opacity: 0.45 !important;
    cursor: default !important;
}
.sharepoint-folder.disabled:hover {
    background-color: #ffffff;
}
.sharepoint-folder.disabled:hover p {
    color: #0A0A0A;
}

.p-file {
    font-size: 13px;
    color: #008941;
    max-width: 200px;
}

.sharepoint.img-folder {
    max-width: 190px;
    max-height: 190px;
    object-fit: contain;
    border-radius: 15px;
}
.sharepoint.img-folder.strategije {
    max-width: 220px;
    max-height: fit-content;
}
.sharepoint.img-folder.strategije {
    max-width: 220px;
    max-height: fit-content;
}
.sharepoint.img-folder.strategije:hover {
    border: 3px solid #008941;
}


.sharepoint.container-column-2 {
    padding: 0 42px;
}
.sharepoint.container-column-2 > .column-2 {

}
.sharepoint.row-2 {
    margin: 12px 0;
    min-width: 100%;
    min-height: 48px;
    background: white;
    border-radius: 25px;
    padding: 8px 21px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.sharepoint.file-container-right {
    display: flex;
    align-items: center;
    justify-content: center;

}
.sharepoint.file-container-right > * {
    margin: 0 14px;
}
.sharepoint.icon {
    color: #008941;
    font-size: 19px;
    cursor: pointer;
}
.sharepoint.file-container-right .sharepoint-icon {
  cursor: pointer;
}
.sharepoint.file-container-right .sharepoint-icon.gray svg path {
    stroke: #707070;
}


.icon-delete-folder:hover > .icon, .icon-delete-folder:hover .sharepoint-folder, .sharepoint.icon.remove:hover {
    color: #b81921;
}

.sharepoint.toolbar .toolbar-actions {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sharepoint.toolbar-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sharepoint.toolbar .toolbar-actions .sharepoint-button {
    margin: 0 4px;
}

.sharepoint.toolbar .toolbar-actions .sharepoint-button:first-child {
    margin-left: 0;
}

.sharepoint.icon.toolbar-icon {
    font-size: 24px;
}

.sharepoint.icon.remove {
    color: #707070;
}
.sharepoint.toolbar-button.button-delete .icon.toolbar-icon path {
    color: #b81921;
}

.toolbar-button.sharepoint-button:hover .sharepoint.icon.toolbar-icon.upload path {
    stroke: white;
}
.toolbar-button.sharepoint-button:hover .sharepoint.icon.toolbar-icon.edit path {
    stroke: white;
}
.toolbar-button.sharepoint-button:hover .sharepoint.icon.toolbar-icon.sort path {
    fill: white;
}

.mime-filter.selected .mime-foto path {
    stroke: white;
}
.mime-filter.selected .mime-video path {
    stroke: white;
}
.mime-filter.selected .mime-pdf path {
    fill: white;
}
.mime-filter.selected .mime-url path {
    stroke: white;
}


.item-list.folders {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.item-list.folders > * {
    margin: 8px;
}
.item-list.folders > *:first-child {
    /*margin-left: 0px;*/
}

.sharepoint-users.row-header {
    top: 200px;
}
.sharepoint-users.item-list {
    top: 240px;
}
.sharepoint-users.item-list .row-box, .user-group-type-container .row-box {
    cursor: pointer;
    margin-left: -30px;
    margin-top: 5px;
    position: absolute;
    height: 16px;
    min-width: 16px;
    background: transparent;
    border: 2px black solid;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sharepoint-users.item-list .row-box-check, .user-group-type-container .row-box-check {
    width: 60%;
    height: 60%;
    background: black;
    border-radius: 4px;
    position: inherit;
}

/* USER / FILE HISTORY */
.user-history-container {
    background: white;
    padding: 10px;
    border-radius: 5px;
}

.item-list.dynamic {
    position: relative;
    right: 0;
    overflow: scroll;
    max-height: 480px;
}

.modal .item-list.dynamic {
    max-height: 150px;
    height: 150px;
}

.modal-auto-height .modal-body {
    max-height: fit-content !important;
}
.dashed-border-title {
    border-bottom: 1px dashed #333;
    padding-bottom: 6px;
    margin-bottom: 6px;
    width: fit-content;
}

.dynamic-header > div{
    font-weight: 800;
    padding-top: 6px;
    padding-bottom: 16px;
}
.dynamic-header > div{
    font-weight: 800;
    padding-top: 6px;
    padding-bottom: 16px;
}

ul.item-list.dynamic {
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: auto;
}

ul.item-list.dynamic li {
    margin: 4px 0;
}

.dynamic.container-column-2 {
    flex-wrap: wrap;
}
.dynamic.container-column-2 .column-2 {
    width: auto;
}
.dynamic.container-column-2 > .column-2:first-child {
    padding-bottom: 80px;
    padding-right: 24px;
}
.dynamic.container-column-2 > .column-2:last-child {
    padding-left: 0px;
}
.row-path {
    min-width: 350px;
    margin-right: 24px;
}
.row-action {
    min-width: 140px;
}

.user-group-type-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 12px 0;
}

.user-group-type-container .row-box {
    position: relative;
    margin: 0 6px;
}
.user-group-type-container .sharepoint-folders-edit.list .row-bar {
    min-width: max-content;
}

.sharepoint.files {
    width: 100%;
    padding: 0 50px;
}

.sharepoint .icon.arrow path{
    fill: #fff;
}

