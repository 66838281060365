.container-background-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    margin-top: 32px;
}
.wrapper-background-image {
    height: 388px;
    width: 280px;
    background: #fff;
    margin-right: 32px;
    cursor: pointer;
}
.wrapper-background-image.selected {
    border: 5px solid #008941;
    border-radius: 13px;
    width: 280px;
    background: #fff;
}
.wrapper-background-image.selected svg {
    width: 42px;
    height: 42px;
    position: absolute;
    margin-left: 210px;
    margin-top: -20px;
    fill: #008941;
}

/* BEER & CIDER */
.container-background-image.beer-cider .wrapper-background-image {
    background-size: cover !important;
    height: 200px;
    width: 284px;
    margin: 15px;
}

.container-background-image.beer-cider {
    flex-grow: initial;
    flex-wrap: wrap;
    padding-bottom: 300px;
}
.main.normal .page {
    height: 100vh !important;
}


