/* default carbon components date picker */

.bx--date-picker {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.bx--date-picker--light .bx--date-picker__input {
    background: #ffffff;
}

.bx--date-picker ~ .bx--label {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

.bx--date-picker-container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.bx--date-picker-input__wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.bx--date-picker.bx--date-picker--simple
.bx--date-picker__input,
.bx--date-picker.bx--date-picker--simple .bx--label {
    width: 7.5rem;
}

.bx--date-picker.bx--date-picker--simple
.bx--date-picker-input__wrapper--invalid .bx--date-picker__input,
.bx--date-picker.bx--date-picker--simple
.bx--date-picker-input__wrapper--invalid ~ .bx--form-requirement,
.bx--date-picker.bx--date-picker--simple
.bx--date-picker-input__wrapper--warn .bx--date-picker__input,
.bx--date-picker.bx--date-picker--simple
.bx--date-picker-input__wrapper--warn ~ .bx--form-requirement {
    width: 9.5rem;
}

.bx--date-picker.bx--date-picker--simple.bx--date-picker--short .bx--date-picker__input {
    width: 5.7rem;
}

.bx--date-picker.bx--date-picker--single
.bx--date-picker__input {
    width: 18rem;
}

.bx--date-picker
.bx--date-picker-input__wrapper--warn
~ .bx--form-requirement {
    color: #161616;
}

.bx--date-picker__input {
    font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.32px;
    outline: 2px solid transparent;
    outline-offset: -2px;
    position: relative;
    display: block;
    height: 2.5rem;
    padding: 0 1rem;
    color: #161616;
    background-color: #f4f4f4;
    border: none;
    border-bottom: 1px solid #8d8d8d;
    -webkit-transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9) all;
    transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9) all;
}

.bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
    outline: 2px solid #008941;
    outline-offset: -2px;
}

@media screen and (prefers-contrast) {
    .bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
        outline-style: dotted;
    }
}

.bx--date-picker__input:disabled {
    color: #c6c6c6;
    background-color: #f4f4f4;
    border-bottom: 1px solid transparent;
    cursor: not-allowed;
}

.bx--date-picker__input:disabled::-webkit-input-placeholder {
    color: #c6c6c6;
}

.bx--date-picker__input:disabled::-moz-placeholder {
    color: #c6c6c6;
}

.bx--date-picker__input:disabled:-ms-input-placeholder {
    color: #c6c6c6;
}

.bx--date-picker__input:disabled::-ms-input-placeholder {
    color: #c6c6c6;
}

.bx--date-picker__input:disabled::placeholder {
    color: #c6c6c6;
}

.bx--date-picker__input:disabled:hover {
    border-bottom: 1px solid transparent;
}

.bx--date-picker__input::-webkit-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
}

.bx--date-picker__input::-moz-placeholder {
    color: #6f6f6f;
    opacity: 1;
}

.bx--date-picker__input:-ms-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
}

.bx--date-picker__input::-ms-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
}

.bx--date-picker__input::placeholder {
    color: #6f6f6f;
    opacity: 1;
}

.bx--date-picker__input--xl {
    height: 3rem;
}

.bx--date-picker__input--sm {
    height: 2rem;
}

.bx--date-picker__icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    fill: #161616;
    width: 19px;
    height: 19px;
}

@media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    .bx--date-picker__icon {
        fill: ButtonText;
    }
}

.bx--date-picker__icon--invalid,
.bx--date-picker__icon--warn {
    cursor: auto;
}

.bx--date-picker__icon--warn {
    fill: #f1c21b;
}

.bx--date-picker__icon--warn path:first-of-type {
    opacity: 1;
    fill: #000000;
}

.bx--date-picker__icon--invalid {
    fill: #da1e28;
}

.bx--date-picker__icon ~ .bx--date-picker__input {
    padding-right: 3rem;
}

.bx--date-picker__input:disabled ~ .bx--date-picker__icon {
    cursor: not-allowed;
    fill: #c6c6c6;
}

.bx--date-picker--range
> .bx--date-picker-container:first-child {
    margin-right: 0.125rem;
}

.bx--date-picker--range .bx--date-picker-container,
.bx--date-picker--range .bx--date-picker__input {
    width: 8.96875rem;
}

.bx--date-picker.bx--skeleton input,
.bx--date-picker__input.bx--skeleton {
    position: relative;
    padding: 0;
    background: #e5e5e5;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    pointer-events: none;
    width: 100%;
}

.bx--date-picker.bx--skeleton input:hover, .bx--date-picker.bx--skeleton input:focus, .bx--date-picker.bx--skeleton input:active,
.bx--date-picker__input.bx--skeleton:hover,
.bx--date-picker__input.bx--skeleton:focus,
.bx--date-picker__input.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default;
}

.bx--date-picker.bx--skeleton input::before,
.bx--date-picker__input.bx--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
    animation: 3000ms ease-in-out skeleton infinite;
    content: '';
}

@media (prefers-reduced-motion: reduce) {
    .bx--date-picker.bx--skeleton input::before,
    .bx--date-picker__input.bx--skeleton::before {
        -webkit-animation: none;
        animation: none;
    }
}

.bx--date-picker.bx--skeleton input::-webkit-input-placeholder, .bx--date-picker__input.bx--skeleton::-webkit-input-placeholder {
    color: transparent;
}

.bx--date-picker.bx--skeleton input::-moz-placeholder, .bx--date-picker__input.bx--skeleton::-moz-placeholder {
    color: transparent;
}

.bx--date-picker.bx--skeleton input:-ms-input-placeholder, .bx--date-picker__input.bx--skeleton:-ms-input-placeholder {
    color: transparent;
}

.bx--date-picker.bx--skeleton input::-ms-input-placeholder, .bx--date-picker__input.bx--skeleton::-ms-input-placeholder {
    color: transparent;
}

.bx--date-picker.bx--skeleton input::placeholder,
.bx--date-picker__input.bx--skeleton::placeholder {
    color: transparent;
}

.bx--date-picker.bx--skeleton .bx--label {
    position: relative;
    padding: 0;
    background: #e5e5e5;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    pointer-events: none;
    width: 4.6875rem;
    height: 0.875rem;
}

.bx--date-picker.bx--skeleton .bx--label:hover, .bx--date-picker.bx--skeleton .bx--label:focus, .bx--date-picker.bx--skeleton .bx--label:active {
    border: none;
    outline: none;
    cursor: default;
}

.bx--date-picker.bx--skeleton .bx--label::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
    animation: 3000ms ease-in-out skeleton infinite;
    content: '';
}

.bx--form-item {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}


.bx--form-item.bx--checkbox-wrapper {
    margin-bottom: 0.25rem;
}

.bx--form-item.bx--checkbox-wrapper:first-of-type {
    margin-top: 0.1875rem;
}

.bx--label + .bx--form-item.bx--checkbox-wrapper {
    margin-top: -0.125rem;
}

.bx--form-item.bx--checkbox-wrapper:last-of-type {
    margin-bottom: 0.1875rem;
}


/* stylelint-disable */
.flatpickr-calendar {
    background: transparent;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    padding: 0;
    -webkit-animation: none;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 315px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background: #fff;
    -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
    opacity: 1;
    visibility: inherit;
    overflow: visible;
    max-height: 640px;
}

.flatpickr-calendar.open {
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 99999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 18rem;
    height: 21rem;
    padding: 0.25rem 0.25rem 0.5rem 0.25rem;
    border: none;
    margin-top: -2px;
    overflow: hidden;
    background-color: #f4f4f4;
}

.flatpickr-calendar.open:focus {
    outline: 1px solid #008941;
}

@media screen and (prefers-contrast) {
    .flatpickr-calendar.open:focus {
        outline-style: dotted;
    }
}

.flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown 110ms cubic-bezier(0, 0, 0.38, 0.9);
    animation: fpFadeInDown 110ms cubic-bezier(0, 0, 0.38, 0.9);
}

.flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px;
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
    z-index: 999;
    display: block;
}

.flatpickr-calendar.hasWeeks {
    width: auto;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
}

.flatpickr-calendar:focus {
    outline: 0;
}

.flatpickr-months {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.flatpickr-month {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.16px;
    height: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    color: #161616;
    line-height: 1;
    text-align: center;
}

.flatpickr-prev-month,
.flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    line-height: 16px;
    height: 28px;
    padding: 10px calc(3.57% - 1.5px);
    z-index: 3;
}

.flatpickr-prev-month,
.flatpickr-next-month {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;
    fill: #161616;
    -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
    background-color: #e5e5e5;
}

.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
    fill: #161616;
}

.flatpickr-next-month.disabled svg,
.flatpickr-prev-month.disabled svg {
    fill: #161616;
    cursor: not-allowed;
}

.flatpickr-next-month.disabled:hover svg,
.flatpickr-prev-month.disabled:hover svg {
    fill: #161616;
}

.flatpickr-current-month {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 1.75rem;
    text-align: center;
}

.flatpickr-current-month .cur-month {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.flatpickr-current-month .cur-month:hover {
    background-color: #e5e5e5;
}

.numInputWrapper {
    position: relative;
    width: 3.75rem;
}

.numInputWrapper:hover {
    background-color: #e5e5e5;
}

.numInputWrapper:hover .arrowUp,
.numInputWrapper:hover .arrowDown {
    opacity: 1;
}

.numInputWrapper:hover .numInput[disabled] ~ .arrowUp,
.numInputWrapper:hover .numInput[disabled] ~ .arrowDown {
    opacity: 0;
}

.numInputWrapper .numInput {
    display: inline-block;
    width: 100%;
    padding: 0.25rem;
    margin: 0;
    border: none;
    color: #161616;
    font-size: inherit;
    font-family: inherit;
    font-weight: 600;
    background-color: #f4f4f4;
    cursor: default;
    -moz-appearance: textfield;
    /* Firefox */
}

.numInputWrapper .numInput:focus {
    outline: 1px solid #008941;
}

@media screen and (prefers-contrast) {
    .numInputWrapper .numInput:focus {
        outline-style: dotted;
    }
}

.numInputWrapper .numInput[disabled], .numInputWrapper .numInput[disabled]:hover {
    color: #c6c6c6;
    background-color: #f4f4f4;
    pointer-events: none;
}

.numInputWrapper .numInput::-webkit-outer-spin-button, .numInputWrapper .numInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

.numInput[disabled] ~ .arrowUp::after {
    border-bottom-color: #c6c6c6;
}

.numInput[disabled] ~ .arrowDown::after {
    border-top-color: #c6c6c6;
}

.numInputWrapper .arrowUp,
.numInputWrapper .arrowDown {
    position: absolute;
    left: 2.6rem;
    width: 0.75rem;
    height: 50%;
    border: none;
    padding: 0 0.25rem 0 0.125rem;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
}

.numInputWrapper .arrowUp::after,
.numInputWrapper .arrowDown::after {
    position: absolute;
    top: 33%;
    display: block;
    content: '';
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
}

.numInputWrapper .arrowUp:hover::after,
.numInputWrapper .arrowDown:hover::after {
    border-bottom-color: #008941;
    border-top-color: #008941;
}

.numInputWrapper .arrowUp:active::after,
.numInputWrapper .arrowDown:active::after {
    border-bottom-color: #008941;
    border-top-color: #008941;
}

.numInputWrapper .arrowUp {
    top: 0.25rem;
    border-bottom: 0;
}

.numInputWrapper .arrowUp::after {
    border-bottom: 0.25rem solid #161616;
}

.numInputWrapper .arrowDown {
    top: 0.6875rem;
}

.numInputWrapper .arrowDown::after {
    border-top: 0.25rem solid #161616;
}

.flatpickr-weekdays {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 2.5rem;
}

.flatpickr-weekdaycontainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

.flatpickr-weekday {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    cursor: default;
    color: #161616;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.flatpickr-days:focus {
    outline: 0;
}

.dayContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    height: 15.375rem;
    padding: 0;
    outline: 0;
}

.flatpickr-calendar.animate .dayContainer.slideLeft {
    -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideLeft,
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
}

.flatpickr-calendar.animate .dayContainer.slideLeftNew {
    -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideRight {
    -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
}

.flatpickr-calendar.animate .dayContainer.slideRightNew {
    -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-day {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    height: 2.5rem;
    width: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #161616;
    cursor: pointer;
    -webkit-transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.flatpickr-day:hover {
    background: #e5e5e5;
}

.flatpickr-day:focus {
    outline: 2px solid #008941;
    outline-offset: -2px;
    outline-color: #008941;
}

@media screen and (prefers-contrast) {
    .flatpickr-day:focus {
        outline-style: dotted;
    }
}

.nextMonthDay,
.prevMonthDay {
    color: #6f6f6f;
}

.flatpickr-day.today {
    position: relative;
    color: #008941;
    font-weight: 600;
}

.flatpickr-day.today::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0.4375rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 0.25rem;
    width: 0.25rem;
    background-color: #008941;
}

.flatpickr-day.today.no-border {
    border: none;
}

.flatpickr-day.today.selected {
    outline: 2px solid #008941;
    outline-offset: -2px;
}

@media screen and (prefers-contrast) {
    .flatpickr-day.today.selected {
        outline-style: dotted;
    }
}

.flatpickr-day.today.selected::after {
    display: none;
}

.flatpickr-day.inRange {
    background-color: #d0e2ff;
    color: #161616;
}

.flatpickr-day.selected {
    color: #ffffff;
    background-color: #008941;
}

.flatpickr-day.selected:focus {
    outline: 0.0625rem solid #ffffff;
    outline-offset: -0.1875rem;
}

.flatpickr-day.startRange.selected {
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 2;
}

.flatpickr-day.startRange.inRange:not(.selected),
.flatpickr-day.endRange.inRange {
    outline: 2px solid #008941;
    outline-offset: -2px;
    background: #f4f4f4;
    z-index: 3;
}

@media screen and (prefers-contrast) {
    .flatpickr-day.startRange.inRange:not(.selected),
    .flatpickr-day.endRange.inRange {
        outline-style: dotted;
    }
}

.flatpickr-day.endRange:hover {
    outline: 2px solid #008941;
    outline-offset: -2px;
    background: #f4f4f4;
    color: #161616;
}

@media screen and (prefers-contrast) {
    .flatpickr-day.endRange:hover {
        outline-style: dotted;
    }
}

.flatpickr-day.endRange.inRange.selected {
    color: #ffffff;
    background: #008941;
}

.flatpickr-day.flatpickr-disabled {
    cursor: not-allowed;
    color: #c6c6c6;
}

.flatpickr-day.flatpickr-disabled:hover {
    background-color: transparent;
}

.flatpickr-input[readonly] {
    cursor: pointer;
}

/* custom override styling*/
.bx--form-item div, .bx--form-item input {
    width: 100% !important;
}
.bx--form-item input {
    background: #fff;
    border-bottom: none;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    height: 56px;
    color: #707070;
}
.bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
    outline: 1px solid #008941;
}