.img-banner-container {
    position: relative;
    left: 370px;
    top: -148px;
    width: fit-content;
    object-fit: contain;
}
.img-banner-container .icon-close {
    font-size: 24px;
    fill: #707070;
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}

.combo-offer-create-container form {
    width: 100%;
    padding-right: 60px;
}