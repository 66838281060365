.dynamic-header {
    display: grid !important;
    grid-template-columns: 1fr 2fr 1fr !important;
    width: 100% !important;
    max-width: unset !important;
}

.dynamic-header > div{
    font-weight: 800;
    padding-top: 6px;
    padding-bottom: 16px;
}
.dynamic-header > div{
    font-weight: 800;
    padding-top: 6px;
    padding-bottom: 16px;
}

ul.item-list.dynamic {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    overflow: inherit;
    padding-bottom: 48px;
}


ul.item-list.dynamic li {
    margin: 4px 0;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}


.row-container {
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
}
.column-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
    align-items: center;
}
.stats-pagination-dynamic {
    width: auto !important;
    padding-right: 50px;
}