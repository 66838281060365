.background.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    //z-index: 999;
}
.page.digital-menu {

}
.online-menu-category {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 22px;
}
.online-menu-title-category {
    line-height: 22px;
    font-size: 18px;
    color: #0A0A0A;
    text-align: left;
    font-weight: bolder;
    padding: 4px 0;
    width: 100%;
}
.online-menu-item-category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
}
.online-menu-item-product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 4px 0;
    height: fit-content;
    min-height: 54px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.42);
}
.online-menu-item-product-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 81%;
}
.online-menu-item-product-left-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.online-menu-title-product {
    line-height: 22px;
    letter-spacing: 1.2px;
    font-size: 14px;
    text-align: left;
    color: #fff;
}
.online-menu-alergens-product {
    line-height: 12px;
    letter-spacing: 0.85px;
    font-size: 9px;
    text-align: left;
    color: #fff;
    margin: 0 6px;
}
.online-menu-unit-product {
    line-height: 22px;
    letter-spacing: 0.85px;
    font-size: 12px;
    text-align: left;
    color: #fff;
}
.online-menu-item-product-right {

}
.online-menu-price-product {
    line-height: 22px;
    letter-spacing: 1.2px;
    font-size: 14px;
    text-align: left;
    color: #fff;
}
.Collapsible {
    width: 100% !important;
    margin: 2px 0;
}
.collapsible-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid rgba(255, 255, 255, 0.42);
}
.collapsible-header.l10n-options {
    justify-content: flex-end;
    border: none;
}
.collapsible-header.l10n-options > div {
    padding: 0 8px;
}
.collapsible-header.l10n-options svg {
 color: rgba(255, 255, 255, 0.45) !important;
}
.l10n-options-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 32px 14px 32px;
}

.collapsible-menu-listing {

}
.online-menu-text-footer {
    line-height: 16px;
    letter-spacing: 0;
    font-size: 12px;
    text-align: center;
    color: #fff;
    font-weight: lighter;
    margin: 30px 0;
    margin-top: 60px;
    bottom: 0;
}

.online-menu-text-footer.bar-info {
    margin-top: 0;
    margin: 30px 0;
    bottom: 0;
}

.online-menu-text-footer.alergens {
    margin: 10px 0;
    bottom: 0;
}