@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

@font-face {
  font-family: 'HeinekenSerifBold';
  src: local('HeinekenSerif18-Bold'), url('/src/assets/fonts/HeinekenSerif18-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'HeinekenSerifMedium';
  src: local('HeinekenSerif18-Medium'), url('/src/assets/fonts/HeinekenSerif18-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'HeinekenSerifRegular';
  src: local('HeinekenSerif18-Regular'), url('/src/assets/fonts/HeinekenSerif18-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'FranklinGothicMedium';
  src: local('FranklinGothicMedium'), url('/src/assets/fonts/FranklinGothicMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'FranklinGothicRegular';
  src: local('FranklinGothicURWCom-Boo'), url('/src/assets/fonts/FranklinGothicURWCom-Boo.ttf') format('truetype');
}
@font-face {
  font-family: 'FranklinGothicDemi';
  src: local('FranklinGothicURWCom-Dem'), url('/src/assets/fonts/FranklinGothicURWCom-Dem.ttf') format('truetype');
}
@font-face {
  font-family: 'Kanit-ExtraBold';
  src: local('Kanit-ExtraBold'), url('/src/assets/fonts/Kanit-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Kanit-Medium';
  src: local('Kanit-Medium'), url('/src/assets/fonts/Kanit-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Kanit-Regular';
  src: local('Kanit-Regular'), url('/src/assets/fonts/Kanit-Regular.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}
p, a, div, img {
  user-select: none;
}
p {
  font-size: 11pt;
  font-family: 'Raleway', sans-serif;
}
a {
  cursor: pointer;
}
.p-title-small {
  font-weight: bold;
  padding-bottom: 16px;
  font-size: 16px;
  color: #0A0A0A;
}
.p-input-label {
  font-size: 16px;
  min-width: fit-content;
}
.p-label-over {
  position: absolute;
  z-index: 99;
  font-size: 16px;
  background: white;
  left: 17px;
}

.p-title-category {
  font-weight: bold;
  padding-bottom: 13px;
  font-size: 14px;
  color: #008941;
}
.p-title-product {
  font-weight: bold;
  padding-bottom: 9px;
  font-size: 14px;
  color: #0A0A0A;
}
.p-desc-product {
  font-weight: normal;
  padding-bottom: 9px;
  font-size: 12px;
  color: #0A0A0A;
}
a, p {
  line-height: 1.15;
}
a.a-icon {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.main {
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: #F0F0F0;
}
.main.before:before {
  background: url('../assets/img/background-cp.jpg');
  content:'';
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 1;
  opacity: 0.58;
}

.page {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.page.relative .body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 140px;
  /*padding-left: 440px;*/
}
.page.centered .body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  /*padding-left: 390px;*/
}
.page.digital-menu .body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  margin-top: 0px;
}
.container-column-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}

.container-column-2 .column-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 50%;
}

.container-row-2 .row-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.container-row-2 .row-2 .row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 50%;
  justify-content: flex-start;
}
.container-row-2 .row-2 .row:first-child {
  padding-right: 32px;
}
.container-row-2 .row-2 form {
  width: 100%;
}
.container-row-2 .row-2 .row form {
  padding-left: 32px !important;
}
.container-row-2 .row-2 form:first-child, .container-column-2 .column-2:first-child {
  padding-right: 16px;
}
.container-row-2 .row-2 form:last-child, .container-column-2 .column-2:last-child, .container-row-2 .row-2 form:nth-child(3) {
  padding-left: 16px;
}

.icon.arrow {
  cursor: pointer;
}
.icon.arrow path{
  fill: #000;
}


.rotate {
  transform: rotate(
          180deg
  );
}

.body.beer-cider-page .l10n-button-group .button-selection:not(.button-selected) {
  display: none;
}

.custom-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.custom-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-body .custom-row form:first-child {
  padding-right: 12px;
}
.modal-body .custom-row form:nth-child(3) {
  padding-left: 12px;
}


/* STATISTICS PAGE */

.icon-stats-sidebar g path {
 fill :#707070;
  stroke: #707070;
  stroke-width: 2px;
}
.stats-card {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 170px;
  background: white;
  align-items: center;
  border-radius: 5px;
}
.p-stats {
  font-size: 45px;
  color: #0A0A0A;
}
.icon-stats {
  height: 60px;
  width: 50px;
  object-fit: contain;
}
.icon-stats g path, .icon-stats g rect {
  fill :#008941;
  /*stroke: #008941;*/
}
.icon-stats-scale g path {
  fill :#008941;
  stroke: #008941;
}
.stats-card form {
  width: 45%;
}
.stats-card form .select-default {
  margin: 0;
}
.stats-card form .select-default .select-default__value-container {
  /*padding: 0*/
}
.stats-subheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 50px;
  height: 70px;
  border-bottom: 1px #707070 solid;
  position: absolute;
  top: 90px;
  right: 50px;
}
.stats-selection {
  padding: 0px 30px;
  cursor: pointer;
  size: 18px;
  color: #6A6A6A;
  border-bottom: none;
  font-weight: bold;
}
.stats-selection.selected {
  width: auto;
  text-decoration: underline;
  color: #008941;
}
.stats-divider {
  height: 1px;
  border-bottom: 1px #707070 solid;
  width: 100%;
}
ul.stats-item-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;
  margin-bottom: 36px;
}
.column-2:first-child ul.stats-item-list {
  align-items: flex-start;
  padding-bottom: 50px !important;

}
.column-2:last-child ul.stats-item-list {
  align-items: flex-end;
  padding-right: 50px;
  padding-bottom: 50px !important;

}
.stats-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  background: #F0F0F0;
  min-height: 50px;
  border-radius: 20px;
  margin: 4px 0;
  min-width: 92%;
}
.stats-inner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
}
.stats-inner-row > div {
  min-width: 100px;
  font-size: 13px;
  padding: 0 4px;
}
.stats-row > .row-name {
  font-size: 15px;
  min-width: 160px;
  max-width: 160px;
}
.stats-inner-row > div:first-child {
  text-align: right;
}
.stats-inner-row > div:last-child {
  text-align: left;
}
.row-value {
  font-size: 28px;
}
.row-remove {
  padding: 0 10px;
  font-weight: bolder;
  cursor: pointer;
}
.row-remove:hover, p.stats-reset:hover {
  color: #E3021C;
  cursor: pointer;
}
p.stats-reset {
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0.12px;
  color: #0A0A0A;
  opacity: 1;
  font-size: 12px;
  min-width: 70px;
}
.stats-body-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 70px;
}
