.table-container-dynamic {
    width: 100%;
}
.dynamic-header-stats {
}
.dynamic-header-stats {
    display: grid !important;
    grid-template-columns: 3fr 1fr 1fr 4fr;
    width: 100% !important;
    max-width: unset !important;
    font-size: 14px;
}
.dynamic-header-stats.col3  {
    grid-template-columns: 3fr 3fr 4fr;
}

.dynamic-header-stats > div {
    font-weight: 800;
    padding-top: 6px;
    padding-bottom: 16px;
    padding-right: 10px;

}

.ul.item-list.dynamic.stats-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    overflow: inherit;
    padding-bottom: 48px;
}


ul.item-list.dynamic.stats-list li {
    margin: 4px 0;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 4fr;
}

.toggle-period {
    zoom: 0.7;
    padding-top: 18px;
    width: 100%;
}

.toggle-period > .button-container {
    margin: 0px 8px;
}

.main.analytics .page {
    height: auto;
}

.main.analytics .container-row-2 .row-2 form:last-child, .container-column-2 .column-2:last-child, .container-row-2 .row-2 form:nth-child(3) {
    padding-left: 0;
}

.main.analytics .container-row-2 .row-2 form:first-child, .container-column-2 .column-2:first-child {
    padding-right: 0;
}

.stats-toggle {
    position: absolute;
    top: 20px;
    right: 60px;
    zoom: 0.8;
}
.stats-toggle-dynamic {
    position: relative;
}
.prices-toggle {
    top: 150px;
}

.stats-toggle > div, .prices-toggle > div {
    margin: 0 8px;
}

.stats-list {
    max-height: unset !important;
}