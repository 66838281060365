ul {
    padding: 12px;
}
ul li {
    display: flex;
}
.table {
    max-width: 100vw;
}
.table .row {
    display: flex;
    height: 125px;
    color: #000 !important;
}
.row > div, .row-header > div {
    max-width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.row-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.row-item p {
    padding: 2px;
}
.row-item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}
.page-pool .body {
    align-items: flex-start;
}
.page-pool p {
    color: #fff !important;
}
.row-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #DCDBDC;
    right: 48px;
    /*left: 440px;*/
    position: absolute;
    top: 140px;
}
.row-header.row-header-has-filter {
    top: 200px;
}

.body.body-has-filter {
    margin-top: 240px;
}

.row-header.row-header-has-pagination {
    top: 200px;
}

.body.body-has-pagination {
    margin-top: 240px;
}
.row-header > div {
    padding-bottom: 8px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
}
.row-header.relative {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #DCDBDC;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
}
.row-header.relative div {
    font-size: 14px;
}

.body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 180px;
}
.row-bar, .row-leading-brand, .row-security, .row-date, .row-dimension, .row-unit, .row-package, .row-alergens {
    min-width: 200px;
}
.row-link {
    min-width: 350px !important;
    max-width: 350px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.row-ad-name {
    min-width: 200px !important;
    max-width: 200px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1355px) {
    .row-ad-name {
        min-width: 150px !important;
        max-width: 150px !important;
    }
}

.row-user {
    min-width: 420px;
}
.row-date-time {
    min-width: 180px;
}
.row-notification, .row-menu, .row-edit, .row-print {
    min-width: 40px;
    cursor: pointer;
}
.row-address, .row-name {
    min-width: 300px;
}
.row-beer-cider {
    min-width: 100px;
}
.row-menu-active.absolute {
    color: #008941;
    position: absolute;
    margin-left: -20px;
    margin-top: 3px;
}
.active-menu.label {
    position: absolute;
    top: -33px;
}
.active-menu.label span{
    color: #008941;
    padding: 0 4px;
}
.container-item-list {
    background: #fff;
    padding: 30px;
}
ul.item-list {
    position: absolute;
    /*left: 440px;*/
    right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}
ul.item-list li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 16px 0;
    font-size: 14px;
}
ul.item-list.relative {
    position: relative;
    left: 0;
    top: 12px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}
ul.item-list.relative li {
    font-size: 14px;
}
.row-bar, .row-name {
    font-weight: bold;
}
.row-stat {
    font-weight: bold;
    width: auto;
    max-width: fit-content;
    min-width: fit-content;
}
.row-stat.normal {
    font-weight: normal;
}


.row-edit .icon-edit-primary {
    stroke: #707070;
}

.search-filters {
    position: absolute;
    top: 91px;
    /*left: 440px;*/
    width: 20%;
    margin-top: 20px;
}
.products-list.search-filters {
    display: flex;
    flex-direction: row;
}
.products-list.search-filters > *:last-child {
    margin: 0 12px;
}
.products-list.search-filters .button-selection {
    margin: 0 8px;
}
.products-list.search-filters input {
    min-width: 250px;
    width: auto;
}

.pagination-container {
    position: absolute;
    top: 91px;
    /*left: 440px;*/
    width: 100%;
    margin-top: 20px;
    display: flex;
}
.pagination-container.pagination-container-bottom {
    width: 100%;
    display: flex;
    position: unset;
    margin-bottom: 20px;
}
.pagination-container .button-container {
    margin-right: 10px;
}
.pagination-container p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    font-size: 20px;
}

.pagination-container.pagination-container-has-filter {
    top: 180px;
}
.row-header.row-header-has-pagination.row-header-has-filter {
    top: 290px;
}
.body.body-has-pagination.body-has-filter {
    margin-top: 330px;
}
.recharts-default-legend {
    display: flex;
    margin-left: 25% !important;
}
