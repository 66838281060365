.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #008941;
    position: fixed;
    bottom: 0;
    height: 80px;
    width: auto;
    /*left: 390px;*/
    right: 0;
}

.footer > .progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
}

.footer > .progress > .dot {
    background: #fff;
    opacity: 1;
    height: 12px;
    width: 12px;
    border-radius: 6px;
    margin: 4px;
}
.footer > .progress > .dot.active {
    opacity: 0.50;
}
.footer a {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    min-width: 300px;
}
.footer a:first-child {
    margin-left: 42px;
    text-align: left;
    justify-content: flex-start;
}
.footer a:last-child {
    margin-right: 42px;
    text-align: right;
    justify-content: flex-end;
}
.footer a svg {
    margin: 0 8px;
    transform: scale(1);
}


